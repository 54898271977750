html,
#body,
#root,
.section-wrapper {
  width: 100%;
  scroll-behavior: smooth;
  background-color: rgb(245, 245, 245);
}

.App {
  font-family: brandon-grotesque, sans-serif;
}

.brandon-font {
  font-family: brandon-grotesque, sans-serif;
}

.section-wrapper {
  min-height: 100%;
}

html,
body {
  height: 100%;
  background-color: whitesmoke !important;
}

.body-wrapper {
  max-width: 1800px;
  margin: 0 auto;
}

.font-loader {
  font-family: 'Montserrat';
}

.grey {
  color: rgb(112, 112, 112);
}

.black {
  color: black;
}

.purple {
  color: #682c8d;
}

.red-background {
  background-color: #e21b54;
}

.purple-background {
  background-color: #a372b5;
}

.cursor-pointer {
  cursor: pointer;
}

.bold {
  font-weight: 700;
}

.bolder {
  font-weight: 900;
}

.isi-text {
  font-size: 18px;
}

.modal-content {
  background-color: #7d449a !important;
  color: #ffffff;
  border-radius: 0.5em !important;
}

.modal-header {
  border-bottom: none !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-link {
  color: #61dafb;
}

.body1 {
  font-size: 16px;
}

.body2 {
  font-size: 16px;
}

.speaker-section {
  display: flex;
  flex-direction: column;
}

.program-info {
  display: flex;
  flex-direction: column;
}

.program-heading {
  text-decoration: none;
  font-weight: 700;
  color: #682c8d;
}

.button-background {
  border: none;
  background: transparent;
}

.speaker-content {
  background-color: white;
  box-shadow: 1px 3px 5px 2px #d7d7d7;
}

.program-dates {
  text-decoration: none;
}

.speaker-modal-name {
  font-weight: 700;
}

.form-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 15px;
}

.register-form-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 40px;
  padding-bottom: 20px;
}

.form-control.modal-input {
  border-radius: 50px;
  width: 287px;
  line-height: 25px;
  border: 1px solid #7b7b7b;
  height: 38px;
  font-size: 13px;
  font-weight: 500;
}

.form-select.modal-input {
  border-radius: 50px;
  width: 287px;
  line-height: 25px;
  border: 1px solid #7b7b7b;
  height: 38px;
  color: #7b7b7b;
  font-size: 13px;
  font-weight: 500;
}

.select-input {
  border-radius: 20px;
  width: 287px;
  line-height: 32px;
  padding: 8px;
  background-color: white;
}

.required::placeholder::after {
  content: '*';
  color: red;
  margin-top: -10px;
  display: inline-block;
}

.register-modal-speaker {
  color: #ffffff;
  font-weight: 700;
}

.contact-text {
  font-size: 14px;
  font-weight: 600;
}

.footer-container {
  background-color: white;
  padding: 10px;
}

.header-container {
  padding: 10px;
}

.isi {
  text-align: left;
  padding-top: 15px;
}

.form-error {
  color: red;
  font-size: 12px;
  font-weight: 500;
  position: relative;
  top: -10px;
}

.register-button {
  font-weight: 700;
  color: white;
  border-radius: 25px;
  text-align: center;
  cursor: pointer;
}

.disable-register {
  font-weight: 700;
  color: white;
  background: grey;
  border-radius: 25px;
  text-align: center;
  cursor: pointer;
}

.success-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 40px;
}

.success-text {
  font-weight: 700;
}

.modal-select option {
  left: 10px;
  position: relative;
}

.footer-logo {
  padding: 10px 0px 10px 0px;
}

.logo-text {
  font-size: 12px;
  padding: 0px 0px 0px 0px;
}

.logo-text1 {
  font-size: 12px;
  padding-top: 15px;
  padding-bottom: 25px;
}

/* Desktop Queries */
@media screen and (min-width: 769px) {
  .desktop-italic {
    font-style: italic;
  }

  .speaker-section {
    margin: -20px 160px auto;
  }

  .program-heading {
    font-size: 20px;
  }

  .program-dates {
    font-size: 18px;
  }

  .program-info-spacing {
    margin-top: 3px;
  }

  .speaker-padding {
    padding-top: 10px;
  }

  .button-background {
    padding-top: 10px;
  }

  .register-button {
    padding: 8px 65px;
  }

  .disable-register {
    padding: 8px 65px;
  }

  .speaker-content {
    display: flex;
    justify-content: space-between;
    background-color: white;
    border-radius: 13px;
    padding: 20px 18px;
    margin: 10px;
    align-items: center;
    /* -webkit-box-shadow: 0 0 5px 2px #d7d7d7;
    -moz-box-shadow: 0 0 5px 2px #d7d7d7; */
  }

  .program-info {
    align-items: flex-start;
  }

  .footer-copyright {
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 20px;
  }

  .footer-container {
    margin: 20px 0px 0px 0px;
  }

  .header-container {
    margin: 20px 0px 0px 0px;
  }

  .contact-text {
    margin: 13px 172px auto;
    text-align: initial;
  }

  .contact-text a {
    color: black;
  }

  .modal-content {
    width: 715px !important;
    top: 70px;
    min-height: 100%;
    right: 135px;
  }

  .speaker-modal-container {
    display: flex;
    flex-direction: row;
    margin-top: -40px;
  }

  .speaker-modal-bio {
    padding: 20px;
  }

  .speaker-image {
    padding: 20px;
  }

  .speaker-modal-name {
    font-size: 20px;
    padding-bottom: 20px;
  }

  .register-modal-speaker {
    font-size: 21px;
  }

  .register-program-info {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    column-gap: 48px;
    margin-top: -35px;
  }

  .success-check {
    max-width: 140px;
  }

  .success-text {
    font-size: 32px;
  }

  .max-program-display {
    max-width: 215px;
  }

  .isi {
    padding-left: 161px;
    padding-right: 168px;
  }

  .checkbox-area {
    max-width: 600px;
    font-size: 14px;
  }

  .checkbox-area1 {
    max-width: 600px;
    font-weight: 700;
    font-size: 21px;
  }

  .join-us {
    font-size: 42px;
  }
}

/* Mobile/Tablet Queries */
@media screen and (max-width: 769px) {
  .speaker-section {
    margin: -23px auto;
    padding-bottom: 25px;
  }

  .speaker-content {
    margin: 25px 0px auto;
    padding: 10px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    align-items: center;
  }

  .program-info {
    row-gap: 5px;
    padding-top: 12px;
  }

  .program-heading {
    font-size: 16px;
  }

  .program-dates {
    font-size: 12px;
  }

  .register-button {
    font-size: 12px;
    padding: 7px 96px;
  }

  .disable-register {
    font-size: 12px;
    padding: 7px 96px;
  }

  .modal-content {
    min-height: 100%;
  }

  .speaker-image {
    text-align: center;
  }

  .register-modal-speaker {
    font-size: 14px;
    position: relative;
    top: -123px;
  }

  .speaker-modal-container {
    margin-top: -25px;
  }

  .speaker-modal-name {
    text-align: center;
    padding: 15px;
  }

  .speaker-modal-bio-text {
    font-size: 12px;
  }

  .footer-container {
    margin: -10px 0 0px 0;
  }

  .contact-text {
    margin-top: -10px;
    padding: 15px 5px 25px 5px;
  }

  .max-program-display {
    max-width: 252px;
  }

  .register-program-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
  }

  .register-form-wrapper {
    margin-top: -67px;
  }

  .success-view {
    padding-top: 100px;
  }
}

/* -------------------------------
-----Only Mobile View----------
------------------------------- */

@media screen and (max-width: 425px) {
  .hide-image {
    display: none;
  }

  .hide-image2 {
    display: block !important;
  }

  .body1 {
    font-size: 14px;
  }

  .body2 {
    font-size: 14px;
  }

  .sya {
    font-size: 14px;
  }

  .success-text {
    padding-bottom: 200px;
  }
}

.form-flex {
  display: flex;
  flex-direction: row;
  margin: 20px auto;
  margin-left: 160px;
  flex-wrap: wrap;
  align-items: center;
}

.zip-code {
  border-radius: 50px;
  width: 200px;
  margin: 0px 10px;
  line-height: 25px;
  border: 1px solid #7b7b7b;
  padding-left: 10px;
  height: 31px;
  left: 44px;
  top: 1px;
  font-size: 13px;
  font-weight: 500;
}

.form-control.zip-code {
  border-radius: 50px;
  width: 200px;
  margin: 0px 10px;
  line-height: 25px;
  border: 1px solid #7b7b7b;
  padding-left: 10px;
  height: 31px;
  left: 44px;
  top: 1px;
  font-size: 13px;
  font-weight: 500;
}

.radius.form-select {
  border-radius: 50px;
  width: 140px;
  margin: 0px 10px;
  height: 31px;
  border: 1px solid #7b7b7b;
  font-size: 13px;
  font-weight: 500;
  color: #6c767d;
}

.black {
  color: black !important;
}

.grey-select {
  color: #6c767d !important;
}

.sya {
  display: flex;
  flex-direction: row;
  margin-left: 185px;
  padding-top: 20px;
  margin-bottom: -10px;
}

.body {
  display: none;
}

@media screen and (max-width: 845px) {
  .form-flex {
    display: flex;
    flex-direction: row;
    margin: 20px auto;
    justify-content: center;
  }

  .zip-code {
    border-radius: 50px;
    width: 200px;
    margin: 0px 10px;
    line-height: 25px;
    border: 1px solid #7b7b7b;
    padding-left: 10px;
    height: 31px;
    left: 44px;
    top: 1px;
  }

  .sya {
    display: flex;
    flex-direction: row;
    margin-left: 60px;
    padding-top: 20px;
    margin-bottom: -10px;
    justify-content: left;
  }

  .success-check {
    max-width: 150px;
  }

  .header-text {
    font-weight: 400;
    font-size: 14px !important;
  }
}

@media screen and (max-width: 677px) {
  .body {
    display: block;
  }

  .body1 {
    text-align: left;
    width: 100%;
    padding-top: 18px;
    padding-left: 0px;
    padding-bottom: 10px;
    word-wrap: break-word;
    color: #111c4e;
    padding: 10px 15px;
  }

  .body2 {
    text-align: left;
    width: 100%;
    padding-top: 18px;
    padding-left: 0px;
    padding-bottom: 10px;
    word-wrap: break-word;
    color: #111c4e;
    padding: 0px 15px;
  }

  .form-flex {
    display: flex;
    flex-direction: column;
    margin: 20px auto;
    flex-wrap: wrap;
    align-items: center;
    row-gap: 10px;
  }

  .zip-code {
    border-radius: 50px;
    width: 320px;
    margin: 10px 10px;
    line-height: 25px;
    border: 1px solid #7b7b7b;
    padding-left: 10px;
    height: 40px;
    left: 44px;
    top: 1px;
  }

  .search-button {
    padding: 5px 80px;
    color: white;
    font-size: 14px;
  }

  .clear-button {
    padding: 4px 57px;
    color: #327fb2;
    font-size: 14px;
  }

  .sya {
    display: block;
    margin: 0 auto;
  }

  .form-control.zip-code {
    width: 275px;
  }

  .radius.form-select {
    width: 275px;
  }
}

@media screen and (max-width: 373px) {
  .small-phone-text {
    font-size: 12px !important;
  }
}
html {
  height: 100%;
}

.body {
  min-height: 100%;
}
